import { Option, Tab, Tabs } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDataValidationCheckpoints } from 'src/service/checkpoint/GetDataValidationCheckpoints';
import { ERROR, INIT, LOADED, LOADING } from 'src/types/Status';
import './validating.scss';
import ValidatingTable from './ValidatingTable';
import ValidatingErrorBanner from './ValidatingErrorBanner';
import { useTranslation } from 'react-i18next';
import { createOptions } from 'src/util/OptionUtil';
import { unique } from 'src/util/ArrayUtil';
import { Employer } from 'src/types/employer/Employer';
import LoadingPage from 'src/components/Common/LoadingPage';
import {
  filterEmployer,
  filterFullName,
  filterSubActionCategories,
  filterValidationTypes,
} from 'src/util/EmployeeFilter';
import { DataValidationEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import { resetCacheList } from 'src/graphql/cache/ApolloCache';
import { EMPLOYEE_IDS_VALIDATION_READY_TO_FINALIZE } from 'src/graphql/cache/CacheKeys';
import FilterComponent from 'src/components/Common/FilterComponent';
import { useEmployersIdLegalName } from 'src/service/employer/GetEmployer';
import { subActionCategories } from 'src/types/SubActionCategory';
import QueryWrapper from '../../Common/QueryWrapper';
import When from '../../Common/When';
import DeviationsTab from './DeviationsTab';
import { GET_REPORTING_ERRANDS_COUNT } from '../../../service/reporting/GetReportingErrandsCount';
import { ReportingErrandsCount } from '../../../types/reporting/ReportingErrandsCount';

interface ValidatingProps {
  customerId: string;
  wageFilePeriod: string;
}

const Validating = ({ customerId, wageFilePeriod }: ValidatingProps) => {
  const { t } = useTranslation();
  const employers: Employer[] = useEmployersIdLegalName(customerId);
  const [nameFilter, setNameFilter] = useState('');
  const [selectedEmployers, setSelectedEmployers] = useState<Option<Employer>[]>([]);
  const [selectedViolationTypes, setSelectedViolationTypes] = useState<Option<string>[]>([]);
  const [selectedSubActionCategories, setSelectedSubActionCategories] = useState<Option<string>[]>([]);

  useEffect(() => {
    resetCacheList(EMPLOYEE_IDS_VALIDATION_READY_TO_FINALIZE);
  }, []);

  const employerIds = employers?.map((e) => e.id);
  const service = useDataValidationCheckpoints(employerIds, wageFilePeriod);
  switch (service.status) {
    case INIT:
    case LOADING:
      return <LoadingPage />;
    case ERROR:
      return <div>{t('common:error')}</div>;
    case LOADED:
      break;
  }
  const responses = service.payload;

  const filteredEmployees = responses
    .filter((response) => filterFullName(response.employee, nameFilter))
    .filter((response) => filterEmployer(response.employee, selectedEmployers))
    .filter((response) => filterValidationTypes(response.checkpoints, selectedViolationTypes))
    .filter((response) => filterSubActionCategories(response.checkpoints, selectedSubActionCategories));

  const violationTypes: string[] = unique(
    responses.flatMap((employee) =>
      employee.checkpoints.flatMap((checkpoint) => checkpoint.additionalData?.fields?.map((field) => field?.key)),
    ),
  ).filter((type) => type !== undefined);

  const handleNameFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNameFilter(e.target.value);
  };

  const employeesWithWageTypeMappingErrors = responses.reduce(
    (accumulator: DataValidationEventReportEmployeeResponse[], response: DataValidationEventReportEmployeeResponse) => {
      const list = response.checkpoints.filter((cp) => cp.subActionCategory === 'MANDATORY_WAGE_TYPE_MAPPING');
      if (list.length > 0) {
        accumulator.push({ ...response, checkpoints: list });
      }
      return accumulator;
    },
    [],
  );

  const employerFilterProps = {
    placeholder: t('employeeFilter:all-employers'),
    options: createOptions(employers || [], null, null, 'legalName'),
    selected: selectedEmployers,
    onChange: (input: Option<Employer>[]): void => {
      setSelectedEmployers([...input]);
    },
  };

  const violationFilterProps = {
    placeholder: t('employeeFilter:all-validations'),
    options: createOptions(violationTypes, t, 'violationTypes'),
    selected: selectedViolationTypes,
    onChange: (input: Option<string>[]): void => {
      setSelectedViolationTypes([...input]);
    },
  };

  const subActionCategoryFilterProps = {
    placeholder: t('employeeFilter:all-subaction-categories'),
    options: createOptions(subActionCategories, t, 'subActionCategories').filter(
      (ac) =>
        ac.value === 'MANDATORY_EMPLOYEE_STORAGE_DATA' ||
        ac.value === 'MANDATORY_EMPLOYEE_CARD_DATA' ||
        ac.value === 'MANDATORY_WAGE_TYPE_MAPPING' ||
        ac.value === 'MISSING_EMPLOYEE_IN_WAGE_FILE' ||
        ac.value === 'UNFINISHED_REPORTING_PREVIOUS_PERIOD',
    ),
    selected: selectedSubActionCategories,
    onChange: (input: Option<string>[]): void => {
      setSelectedSubActionCategories([...input]);
    },
  };

  return (
    <div className={['validating'].join(' ')}>
      <div className="validating__banner-container">
        <ValidatingErrorBanner employeeResponses={employeesWithWageTypeMappingErrors} />
      </div>
      <FilterComponent
        showSearch
        searchPlaceholder={t('searchTerms:search-employee')}
        searchFilter={nameFilter}
        onSearchFilterChange={handleNameFilterChange}
        selectProps={[employerFilterProps, violationFilterProps, subActionCategoryFilterProps]}
      />

      <Tabs>
        <Tab title={`${t('validating:tabs.complete')} (${responses.length})`}>
          <ValidatingTable employeeResponses={filteredEmployees} customerId={customerId} />
        </Tab>
        <Tab title={`${t('validating:tabs.deviations')}`}>
          <QueryWrapper
            type="list"
            query={GET_REPORTING_ERRANDS_COUNT}
            options={{
              variables: {
                request: {
                  employerIds: employerIds,
                  wageFilePeriod: wageFilePeriod,
                  errandType: 'WAGE_FILE',
                },
              },
            }}
          >
            {(reportingErrandsCount: ReportingErrandsCount) => {
              return (
                <When condition={reportingErrandsCount.count > 0}>
                  <DeviationsTab wageFilePeriod={wageFilePeriod} />
                </When>
              );
            }}
          </QueryWrapper>
        </Tab>
      </Tabs>
    </div>
  );
};

export default Validating;
