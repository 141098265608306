import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DrawerNavigation, Icon, InlineEdit, ListGroup, Typography } from 'fgirot-k2-ui-components';
import DrawerBase from 'src/components/Common/DrawerBase';
import { PglBonus } from 'src/types/employees/PglOutcome';
import {
  formatSweAmountTextWithSuffixNoDecimal,
  formatSweAmountTextWithSuffixWithTwoDecimals,
} from 'src/util/Number/AmountFormatter';
import './bonus-overview-drawer.scss';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';

interface BonusOverviewDrawerProps {
  onClose: () => void;
  onBackClick: () => void;
  pglBonus: PglBonus;
  employerWageType: EmployerWageType;
}

export const BonusOverviewDrawer: FC<BonusOverviewDrawerProps> = ({
  onClose,
  onBackClick,
  pglBonus,
  employerWageType,
}) => {
  const { t } = useTranslation();

  return (
    <DrawerBase
      title={employerWageType.name}
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      onClose={onClose}
    >
      <div className="bonus-overview-drawer">
        <ListGroup variant="drawer-navigation">
          {pglBonus.yearlyBonusSums.map((yearlyBonusSum, index) =>
            pglBonus.bonusAverageAmount > 0.0 ? (
              <DrawerNavigation
                key={`BonusOverviewDrawer-${yearlyBonusSum.year}-${index}`}
                label={t('common:year') + ` ${yearlyBonusSum.year}`}
                value={formatSweAmountTextWithSuffixWithTwoDecimals(yearlyBonusSum.bonusSum)}
                onClick={() => alert('not implemented yet')}
              />
            ) : (
              <InlineEdit
                key={`BonusOverviewDrawer-${yearlyBonusSum.year}-${index}`}
                label={t('common:year') + ` ${yearlyBonusSum.year}`}
                bold
              >
                {formatSweAmountTextWithSuffixWithTwoDecimals(yearlyBonusSum.bonusSum)}
              </InlineEdit>
            ),
          )}
        </ListGroup>
        <ListGroup variant="inline-edit">
          <InlineEdit bold label={t('calculations:sum')}>
            <Typography variant="body2">
              {formatSweAmountTextWithSuffixNoDecimal(pglBonus.bonusAverageAmount)}
            </Typography>
          </InlineEdit>
        </ListGroup>
      </div>
    </DrawerBase>
  );
};
export default BonusOverviewDrawer;
