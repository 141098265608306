import { Button, Card, CardAction, Modal, Option, Select, Switch, Typography } from 'fgirot-k2-ui-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './create-pay-type-modal.scss';
import { useCreateOrUpdateEmployerPayType } from 'src/service/wagetype/CreateOrUpdateEmployerPayType';
import { useEmployerKubs } from 'src/service/employer/GetEmployerKubs';
import TextInput from 'src/components/Common/TextInput';
import { CreateOrUpdateEmployerPayType } from 'src/types/CreateOrUpdateEmployerPayType';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import { isHourlySalary } from 'src/util/WageUtil';

interface CreatePayTypeModalProps {
  open: boolean;
  employerId: string;
  employerWageTypes: EmployerWageType[];
  onClose: () => void;
}

const CreatePayTypeModal: React.FunctionComponent<CreatePayTypeModalProps> = ({
  open,
  onClose,
  employerId,
  employerWageTypes,
}) => {
  const { t } = useTranslation();
  const createOrUpdateWageTypeMapping = useCreateOrUpdateEmployerPayType();
  const filteredEmployerWageTypes = employerWageTypes.filter((wageType) => wageType.wageType !== 'GROSS_SALARY');

  const [request, setRequest] = useState<CreateOrUpdateEmployerPayType>({
    number: '',
    name: '',
    employerWageTypeId: null,
    inUse: false,
    useQuantity: true,
    kubTypeMappings: [],
  });

  const employerKubs = useEmployerKubs(employerId);

  const wageTypeOptions = filteredEmployerWageTypes.map((wt) => ({
    label: wt.name,
    value: wt,
  }));

  const employerKubOptions = [
    ...employerKubs.map((employerKub) => ({
      label: employerKub.kubType,
      value: employerKub.kubType,
    })),
  ];

  const createButtonDisabled =
    !request.employerWageTypeId || !request.number || request.number.length > 64 || request.name.length > 255;

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequest((prev) => {
      return { ...prev, number: event.target.value };
    });
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequest((prev) => {
      return { ...prev, name: event.target.value };
    });
  };

  const handleSelectKubTypes = (options: Option<string>[]) => {
    setRequest((prev) => {
      return { ...prev, kubTypeMappings: options.map((option) => option.value) };
    });
  };

  const handleSelectWageType = (option: Option<EmployerWageType>) => {
    setRequest((prev) => {
      return { ...prev, employerWageTypeId: option.value.id };
    });
  };

  const handleAddPayType = () => {
    createOrUpdateWageTypeMapping(employerId, request);
    onClose();
  };

  useEffect(() => {
    // If user selects a wage type that is not hourly salary, useQuantity should always be true
    if (
      !isHourlySalary(employerWageTypes.find((wageType) => wageType.id === request.employerWageTypeId)?.wageType) &&
      !request.useQuantity
    ) {
      setRequest((prev) => ({ ...prev, useQuantity: true }));
    }
  }, [request]);

  return (
    <Modal data-cy="create-pay-type-modal" centered overlayBackground open={open} onClose={onClose}>
      <Card
        title={t('account:employers-tab.wage-type-mapping-settings.add-wage-type-mapping-modal.title')}
        size="narrow"
      >
        <div className="create-pay-type-modal">
          <div>
            <TextInput
              label={`${t(
                'account:employers-tab.wage-type-mapping-settings.add-wage-type-mapping-modal.employer-wage-type',
              )}`}
              type="text"
              validationKey="text64"
              variant="default"
              value={request.number}
              maxLength={65}
              mandatory
              onChange={handleNumberChange}
            />
          </div>
          <div>
            <TextInput
              label={`${t(
                'account:employers-tab.wage-type-mapping-settings.add-wage-type-mapping-modal.description-placeholder',
              )}`}
              type="text"
              validationKey="text255"
              variant="default"
              maxLength={256}
              value={request.name}
              onChange={handleNameChange}
            />
          </div>
          <div>
            <Select
              label={`${t('account:employers-tab.wage-type-mapping-settings.add-wage-type-mapping-modal.wage-type')}`}
              options={wageTypeOptions}
              selected={wageTypeOptions.find((option) => option.value.id === request.employerWageTypeId)}
              onChange={handleSelectWageType}
              placeholder={t('common:select')}
              small
              mandatory
              outlined
            />
          </div>
          {employerKubOptions.length > 0 && (
            <div>
              <Select
                label={`${t('account:employers-tab.wage-type-mapping-settings.add-wage-type-mapping-modal.kub')}`}
                multiple
                selectAll
                selectAllLabel={t('common:all')}
                options={employerKubOptions}
                selected={employerKubOptions.filter((option) => request.kubTypeMappings.includes(option.value))}
                onChange={handleSelectKubTypes}
                placeholder={t('common:select')}
                small
                outlined
              />
            </div>
          )}
          <div>
            <Typography variant="caption">
              {t('account:employers-tab.wage-type-mapping-settings.in-use.title')}
            </Typography>
            <Switch
              toggled={request.inUse}
              onChange={() => setRequest((prev) => ({ ...prev, inUse: !prev.inUse }))}
              data-cy="create-pay-type-modal__in-use-switch"
            />
          </div>
          {isHourlySalary(
            employerWageTypes.find((wageType) => wageType.id === request.employerWageTypeId)?.wageType,
          ) && (
            <div>
              <Typography variant="caption">
                {t('account:employers-tab.wage-type-mapping-settings.hourly-salary-switch-label')}
              </Typography>
              <Switch
                toggled={request.useQuantity}
                onChange={() => setRequest((prev) => ({ ...prev, useQuantity: !prev.useQuantity }))}
              />
            </div>
          )}
        </div>
        <CardAction>
          <Button
            label={t('common:cancel')}
            type="link"
            onClick={onClose}
            data-cy="create-pay-type-modal__cancel-button"
          />
          <Button
            label={t('common:add')}
            disabled={createButtonDisabled}
            onClick={handleAddPayType}
            data-cy="create-pay-type-modal__create-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default CreatePayTypeModal;
