import { gql } from '@apollo/client';

export const GET_EMPLOYMENT_EVENT_CHECKPOINTS = gql`
  query GetEmploymentEventCheckpoints($employerIds: [UUID]!, $wageFilePeriod: String!) {
    reportEmployees(employerIds: $employerIds, wageFilePeriod: $wageFilePeriod, actionCategory: "EMPLOYMENT_EVENT") {
      employee {
        id
        firstName
        lastName
        personNumber
        employerId
        employmentStartDate
        employmentEndDate
        hasDraft
      }
      employeeCard {
        id
        employeeId
        employmentGroupId
      }
      checkpoints {
        id
        errandId
        employerId
        subActionCategory
        wageFilePeriod
        checkpointStatus
        additionalData {
          employeeCardId
          employeeCardPeriod
          eventFlowType
          wageTypesWithInvalidSum
          individualChoiceId
          kubSums {
            type
            sum
          }
          previousEmployeeId
          wageFileEmploymentStartDate
          wageFileEmploymentEndDate
        }
      }
    }
  }
`;
