import { gql } from '@apollo/client';

export const EMPLOYMENT_EVENT_CHECKPOINT = gql`
  subscription EmploymentEventCheckpoint($employerIds: [UUID]!, $wageFilePeriod: String!) {
    checkpoint(employerIds: $employerIds, wageFilePeriod: $wageFilePeriod, actionCategory: "EMPLOYMENT_EVENT") {
      id
      errandId
      errandEntryId
      employeeId
      employerId
      actionCategory
      subActionCategory
      checkpointStatus
      finalized
      additionalData {
        employeeCardId
        wageTypesWithInvalidSum
        employeeCardPeriod
        individualChoiceId
        eventFlowType
        kubSums {
          type
          sum
        }
        previousEmployeeId
        wageFileEmploymentStartDate
        wageFileEmploymentEndDate
      }
    }
  }
`;
